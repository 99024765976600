import React, { useEffect } from "react";
import styled from "styled-components";

function App() {
  useEffect(() => {
    window.location.href = "https://www.tryfinancy.com";
  }, []);

  return (
    <Main>
      <p>Redirecting...</p>
    </Main>
  );
}

export default App;

const Main = styled.div``;
